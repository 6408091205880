import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Eps } from '../../../models/eps.model';
import { DatoFacturado } from '../../../models/mfactu/dato-facturado.model';
import { ReporteEntrega } from '../../../models/msumin/reporte-entrega.model';
import { Regimen } from '../../../models/regimen.model';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-modal-factura-rips',
  templateUrl: './modal-factura-rips.component.html',
  styleUrls: ['./modal-factura-rips.component.scss']
})
export class ModalFacturaRipsComponent implements OnInit {

  @Input() public prescripcion: string;
  @Input() public tutela: boolean;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public datoFact: DatoFacturado;
  reporteEntrega: ReporteEntrega = null;

  enviandoForm: boolean = false;

  facturaForm: FormGroup = null;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    this.facturaForm = this.fb.group({
      numeroFactura: [''],
      nitPrestador: ['']
    });
    if (this.datoFact) {
      this.facturaForm.patchValue({
        numeroFactura: this.datoFact.numFactura,
        nitPrestador: this.datoFact.nitPrestador,
      })
    }

    // cargar reportes de entrega
    if (this.eps) {
    this.apiService.reportesEntregasService.byNumero(this.eps.identificacion, this.regimen.codigo, this.datoFact.NoPrescripcion)
      .subscribe(data => {
        this.reporteEntrega = this.apiService.reportesEntregasService.filtroReporteEntrega(data.reportes,
          this.datoFact.TipoTec, this.datoFact.ConTec, this.datoFact.NoEntrega)
      });
    }
  }

  onSubmit() {
    let { numeroFactura, nitPrestador } = Object.assign({}, this.facturaForm.value);
    let datos = {
      'numIdentificacion': this.datoFact.NoIDPaciente,
      'tipoIdentificacion': this.datoFact.TipoIDPaciente,
      'codTec': this.datoFact.ConTec,
      'tipoTec': this.datoFact.TipoTec,
      'numEntrega': this.datoFact.NoEntrega,
      'idProceso': this.reporteEntrega ? this.reporteEntrega.ID: '',
      'numeroFactura': numeroFactura,
      'nitPrestador': nitPrestador,
    }
    this.apiService.datosFacturadosService
      .facturaRips(this.eps.identificacion, this.regimen.codigo, this.prescripcion, datos)
      .subscribe(
        data => {
          this.modalClose.nativeElement.click();
        },
        error => {
          this.apiService.notifService.error("Error", error);
        }
      );
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

}
