const relacionUrls = [
   {client: 'http://localhost:4200', backend: 'http://localhost:8088/'},
   {client: 'http://192.168.51.214', backend: 'http://192.168.51.214:8088/'},
   {client: 'http://192.168.51.216', backend: 'http://192.168.51.216:8088/'},
   {client: 'http://192.168.36.211', backend: 'http://192.168.36.211:8088/'},
   {client: 'http://192.168.36.62:8084', backend: 'http://192.168.36.62:8088/'},
   {client: 'http://epsonline.comfaoriente.com:65214', backend: 'http://epsonline.comfaoriente.com:65215/'},
   {client: 'https://epsmipres.comfaoriente.com', backend: 'https://epsmipresapi.comfaoriente.com/'},
   {client: 'http://epsonline.comfaoriente.co:65214', backend: 'http://epsonline.comfaoriente.co:65215/'}
]

const getURlBackend = () => {

   let client = window.location.origin

   let actual = relacionUrls.filter(item => item.client == client)

   if (actual && actual.length==1) {
      return actual[0].backend
   }
   return client
}

export const REST = getURlBackend();
export const API_REST = REST+"api/";
export const API_REST_CUM_OTROS = "https://www.datos.gov.co/resource/spzp-dfuc.json";
export const API_REST_CUM_PENDIENTES = "https://www.datos.gov.co/resource/vgr4-gemg.json";
export const API_REST_CUM_VENCIDOS = "https://www.datos.gov.co/resource/vwwf-4ftk.json";
export const API_REST_CUM_VIGENTES = "https://www.datos.gov.co/resource/i7cb-raxc.json";

export const API_REST_IUM = "https://www.datos.gov.co/resource/sdmr-tfmf.json";

export const TOKEN = {
   TOKEN_KEY: 'AuthToken',
   USUARIO_NAME_KEY: 'AuthUserName',
   USUARIO_USERNAME_KEY: 'AuthUserUsername',
   USUARIO_NIT_KEY: 'AuthUserNit',
   USUARIO_ROLES_KEY: 'AuthUserRoles'
}
export const NAME_APP = "Mipres Comfaoriente"

